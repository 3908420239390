import { CSSProperties, ReactNode } from 'react';
import { Box } from '@mui/material';
import cn from 'classnames';

export const CarouselItem = (props: {
  index: number;
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}) => {
  return (
    <Box
      data-testid={`carousel-item-${props.index}`}
      className={cn(
        'relative w-[448px] ml-[32px]',
        { 'md:pl-[64px]': props.index === 0 },
        props.className
      )}
      key={props.index}
      style={props.style}
    >
      {props.children}
    </Box>
  );
};
