import { useState } from 'react';
import { Box, Dialog, Divider } from '@mui/material';
import { useTranslations } from 'next-intl';
import cn from 'classnames';
import CloseIcon from './close-icon';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import { SliceWrapper } from '@ee-monorepo/prismic/shared/ui';
import dynamic from 'next/dynamic';
import {
  fullWidthBlockSerializer,
  richTextSerializerHeading1Override,
} from '@ee-monorepo/prismic/shared/serializers';
import { appConfig } from '@ee-monorepo/shared/utilities/constants';
import { PrismicRichText } from '@prismicio/react';
import {
  CarouselContainer,
  CarouselItem,
} from '@ee-monorepo/shared/ui/carousel';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });

export const VideoReviews = ({ index, slice: { primary, items } }) => {
  const isMobile = useHandHeld();
  const translations = useTranslations();
  const [selectedVideo, setSelectedVideo] = useState(undefined);
  const handleClose = () => {
    setSelectedVideo('');
  };

  const videoSection = items?.map((item, i) => {
    const thumbnail = isMobile ? item?.thumbnail?.mobile : item?.thumbnail;
    const handleVideoClick = () => {
      setSelectedVideo(item?.video_url);
    };
    return (
      <CarouselItem
        key={i}
        index={i}
        style={{ minWidth: thumbnail?.dimensions?.width || 0 + 32 }}
      >
        <Box
          className={'relative overflow-hidden'}
          style={{ height: thumbnail?.dimensions?.height || 0 }}
        >
          <img
            data-testid="image-play"
            className={`cursor-pointer block absolute top-1/2 
            left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2
            [&:hover+img]:opacity-100 
            `}
            src={thumbnail?.url}
            alt={thumbnail?.alt}
            onClick={handleVideoClick}
          />
          <img
            data-testid="button-play"
            className={`cursor-pointer block 
            absolute top-1/2 left-1/2 max-w-none -translate-x-1/2 -translate-y-1/2 
            opacity-70 hover:opacity-100 max-[400px]:h-[44px] max-[400px]:w-[44px]`}
            src="/assets/icons/system/play_icon.svg"
            alt={`${translations('shared.snap_play_icon')}`}
            onClick={handleVideoClick}
          />
        </Box>
        <Divider className={'mx-0 my-8'} />
        <Box>
          <PrismicRichText
            field={item?.quote}
            components={richTextSerializerHeading1Override}
          />
          <Box className="bodyHeavyFont">{item?.author_name}</Box>
          <Box className="secondaryFont text-SnapGrey400">
            {item?.author_location}
          </Box>
        </Box>
      </CarouselItem>
    );
  });

  return (
    <SliceWrapper
      data-testid="video-reviews"
      data-slice-index={index}
      className={cn(`flex flex-col items-center w-full max-w-[1440px] self-center max-[767px]:px-0`, 'snap-container')}
    >
      <Box className={'w-full'}>
        <Box className="sm:text-left md:text-center sm:px-6">
          <Box className="text-SnapDarkBlue">
            <PrismicRichText
              field={primary?.title}
              components={richTextSerializerHeading1Override}
            />
          </Box>
          <div className="text-SnapGrey500">
            <PrismicRichText
              field={primary?.sub_title}
              components={fullWidthBlockSerializer}
            />
          </div>
        </Box>
        <CarouselContainer>{videoSection}</CarouselContainer>
      </Box>
      <Dialog
        onClose={handleClose}
        open={!!selectedVideo}
        classes={{ paper: `overflow-visible max-h-none max-w-none` }}
      >
        <Box className={'h-auto w-auto md:h-[486px] md:w-[864px]'}>
          <ReactPlayer
            url={selectedVideo?.embed_url}
            config={{
              youtube: {
                playerVars: {
                  widget_referrer: appConfig.siteUrl,
                },
              },
            }}
            controls
            height="100%"
            width="100%"
          />
          <CloseIcon
            data-testid="close"
            className={'cursor-pointer absolute block -top-6 -right-6 max-w-none h-11 w-11 text-SnapGreen'}
            onClick={handleClose}
          />
        </Box>
      </Dialog>
    </SliceWrapper>
  );
};
