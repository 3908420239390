import { ReactNode } from 'react';
import { Box } from '@mui/material';
import cn from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';

export const CarouselContainer = (props: {
  children: ReactNode;
  className?: string;
}) => {
  const [viewportRef] = useEmblaCarousel({
    dragFree: true,
    loop: true,
    align: 'start',
    containScroll: 'trimSnaps',
  });

  return (
    <Box className={cn('relative px-4 py-[64px] mx-auto w-full', props.className)}>
      <div
        className={'overflow-hidden cursor-grabbing'}
        ref={viewportRef}
      >
        <div
          className={'flex select-none touch-none -ml-[10px]'}
          data-testid={`carousel-container`}
        >
          {props.children}
        </div>
      </div>
    </Box>
  );
};
