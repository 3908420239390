import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CloseIcon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width={48}
    height={48}
    viewBox="0 0 48 48"
    fill="none"
    color="primary"
  >
    <path
      d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
      fill="white"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31 18.41L29.59 17L24 22.59L18.41 17L17 18.41L22.59 24L17 29.59L18.41 31L24 25.41L29.59 31L31 29.59L25.41 24L31 18.41Z"
    />
  </SvgIcon>
);

export default CloseIcon;
